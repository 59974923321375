import * as React from "react";
import {API} from "../../../api";
import {Fragment, useEffect, useState} from "react";
import {Loader} from "../../Loader";

export const Image = ({imageId = undefined, isRunRecognition = false}) => {

    const [imagePath, setImagePath] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [viewBlock, setViewBlock] = useState(false)

    useEffect(() => {
        if (imageId !== undefined) {
            getPathToRecognizeFile(imageId)
        }
    }, [imageId])

    useEffect(() => {
        if (isRunRecognition) {
            setShowLoader(true)
            setViewBlock(true)
        }
    }, [isRunRecognition])
    const getPathToRecognizeFile = (id) => {
        API.getPathToRecognizeFile(id)
            .then(({data}) => {
                setImagePath(URL.createObjectURL(new Blob([data], {type: "image/jpeg"})))
            })
            .catch(error => {
                console.error("RecognitionError", error)
            })
            .finally(() => {
                setShowLoader(false)
                // setIsRunRecognition(false);
            })
    }

    return(
        <div className="item-recognize-dictation">
            {
                viewBlock &&
                <Fragment>
                    <hr className="item-recognize-dictation-hr"/>
                    <div style={{marginBottom: "1rem"}}>
                        <strong style={{fontSize: "16px"}}>Найденные слова</strong>
                    </div>
                    {
                        imagePath &&
                        <a href={imagePath} target="_blank">
                            <img
                                src={imagePath}
                                alt=""
                                style={{maxWidth: "260px"}}
                            />
                        </a>
                    }
                    <Loader hidden={!showLoader}/>
                </Fragment>
            }
        </div>
    )
}
