import * as axios from 'axios';

const createRoute = (path = "") => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + `/math-analyze/${path}`;
const createCfg = () => {
    return {
        responseType: 'json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'image/png'
        }
    }
}

export async function getResultMathAnalyze(req) {
    try {
        return await axios.post(createRoute(), {data: req}, createCfg());
    } catch (err) {
        return null;
    }
}

export async function getResultMathAnalyzeFraction(req) {
    try {
        return await axios.post(createRoute("fraction/"), {data: req}, createCfg());
    } catch (err) {
        return null;
    }
}
