import {Loader} from "../../Loader";
import * as React from "react";

export const Example = ({handler, isRunRecognition}) => {

    const handleChange = (event) => {
        handler(event.target.files[0])
    }

    return (
        <div className="item-recognize-dictation">
            <div>
                <div style={{marginBottom: "1rem"}}>
                    <strong style={{fontSize: "16px"}}>Пример</strong>
                </div>
                <img style={{maxWidth: "250px"}} src="/img/recognizer-dictation-example.jpg" alt=""/>
            </div>
            <div style={{display: "grid", gridAutoFlow: "column"}}>
                <input type="file" style={{marginTop: "1rem"}} onChange={handleChange}/>
            </div>


        </div>
    )
}
