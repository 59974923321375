import {API} from "../api";
import {recognitionMathLatexByteStream} from "../api/Recognition/v2/recognitionMathLatexByteStream";

const compressImage = async (file) => {
    let sendFile = file
    if ((sendFile.size / 1024) > 300) {
        const newFile = await API.imageCompression(file)
        sendFile = new File([newFile.data], "file.png", {type: newFile.type})
    }
    return sendFile
}

export const recognizeImageDictation = async (file) => {

    const recognitionResult = await API.recognitionByteStream(await compressImage(file))

    const result = {
        message: "",
        imageID: "",
        error: false
    }

    if (recognitionResult) {
        result.message = recognitionResult.data.text
        result.imageID = recognitionResult.data.id_vis
    } else {
        result.error = true
        return result
    }

    if (result.message) {
        const handleText = await API.HandlerRegexp(result.message)

        if (handleText.data && handleText.data.handled_text) {
            result.message = handleText.data.handled_text
        }
    }

    return result
}

export const recognizeImageMathLatex = async (file) => {

    const result = {
        solution: [],
        imageID: null,
        error: false
    }

    const recognitionResult = await API.recognitionMathLatexByteStream(await compressImage(file))

    if (recognitionResult.data.length) {
        for (const item of recognitionResult.data) {
            if (result.imageID === null) {
                result.imageID = item.id_svae
            }

            result.solution.push(item.text.text)
        }
    } else {
        result.error = true;
    }

    console.log('recognizeImageMathLatex', result)
    return result;
}
