import * as axios from 'axios';
import {userSpeechId} from "../../App";

const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/save-user-speech/';
const createBody = (message) => ({
    id: userSpeechId,
    message: message
});
const createCfg = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}

export async function saveUserSpeech(message) {
    try {
        const res = await axios.post(createRoute(), createBody(message), createCfg());

        return res;
    } catch (err) {
        return null;
    }
}
