import {useTranslation} from "react-i18next";
import * as React from "react";
import {Fragment, useState} from "react";
import {API} from "../../api";
import {Loader} from "../Loader";
import {getResultMathAnalyze} from "../../api/Recognition/v2/mathAnalyze";

export function RecognizeItemMaths() {

    const {t} = useTranslation();
    const [isRunRecognition, setIsRunRecognition] = useState(false);
    const [imageRecognition, setImageRecognition] = useState('');
    const [gradeInfo, setGradeInfo] = useState({})

    const handleChange = (event) => {
        runRecognition(event.target.files[0])
    }

    const runRecognition = async (file) => {

        setIsRunRecognition(true);

        let fileSend = file

        if ((file.size / 1024) > 300) {
            const newFile = await API.imageCompression(file)
            fileSend = new File([newFile.data], "file.png", {type: newFile.type})
        }

        API.recognitionMathByteStream(fileSend)
            .then((data) => {
                console.log("recognitionMathByteStream", data)

                if (!data?.data) {
                    return
                }

                let result = [];
                let id_svae = -1

                for (let item of data.data) {
                    id_svae = item.id_svae
                    result.push(item.text.text)
                }

                if (id_svae > -1) {
                    getPathToRecognizeFile(id_svae)
                } else {
                    console.log("не удалось найти id_svae")
                }

                if (result.length) {
                    checkResult(result.join("##"))
                } else {
                    console.log("Не удалось сформировать строку result")
                }
            })
            .catch(error => {
                console.error("RecognitionError", error)
            })
            .finally(() => {
                console.log("start recognitionMathByteStream", Date())
                setIsRunRecognition(false);
            })
    }

    const getPathToRecognizeFile = (id) => {
        API.getPathToRecognizeMathFile(id)
            .then(({data}) => {
                setImageRecognition(URL.createObjectURL(new Blob([data], {type: "image/jpeg"})))
            })
            .catch(error => {
                console.error("RecognitionError", error)
            })
    }

    const checkResult = (result) => {
        console.log("checkResult", result)
        API.getResultMathAnalyze(result)
            .then(({data}) => {
                setGradeInfo({})
                const referenceSolution = [];
                const userSolution = [];
                console.log("result data", data.referenceSolution)
                for (const key in data.referenceSolution) {
                    referenceSolution.push(data.referenceSolution[key])
                }
                for (const key in data.userSolution) {
                    userSolution.push(data.userSolution[key])
                }

                data.referenceSolution = referenceSolution
                data.userSolution = userSolution

                console.log("data", data)
                setGradeInfo(data)
                // setImageRecognition(URL.createObjectURL(new Blob([data], {type: "image/jpeg"})))
            })
            .catch(error => {
                console.error("getResultMathAnalyze", error)
            })
    }


    return (
        <div className="container-recognize-dictation">
            <div style={{fontSize: "20px", display: 'flex', flexDirection: 'column'}}>
                <strong>
                    Решите уравнение по шагам
                </strong>
                <pre style={{
                    fontSize: "26px",
                    backgroundColor: "#d7d7d7",
                    padding: "1rem",
                    width: "fit-content",
                    marginTop: "1rem"
                }}>
                    4x+5*12=78-10
                </pre>
            </div>

            <div className="recognize-dictation-item">

            </div>
            <div className="container-recognize-dictation-result">
                <div className="item-recognize-dictation">
                    <div>
                        <div style={{marginBottom: "1rem"}}>
                            <strong style={{fontSize: "16px"}}>Пример фотографии с решением</strong>
                        </div>
                        <img style={{maxWidth: "250px"}} src="/img/recognizer-maths-example.jpg" alt=""/>
                    </div>
                    <div style={{display: "grid", gridAutoFlow: "column"}}>
                        <input type="file" style={{marginTop: "1rem"}} onChange={handleChange}/>
                        <div className="recognize-result-loader">
                            <Loader hidden={!isRunRecognition}/>
                        </div>
                    </div>


                </div>

                {
                    gradeInfo?.grade ? (
                        <div className="item-recognize-dictation item-recognize-dictation-math">
                            <hr className="item-recognize-dictation-hr"/>
                            <div style={{marginBottom: "1rem", display: "flex", justifyContent: "center"}}>
                                <strong style={{fontSize: "16px"}}>Оценка решения</strong>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <div style={{marginBottom: "1rem"}}>
                                        <strong style={{fontSize: "16px"}}>Правильное решение</strong>
                                    </div>
                                    {
                                        gradeInfo?.referenceSolution.map((item, index) => {
                                            let className = "line";
                                            if (Array.isArray(gradeInfo.lineEmpty)) {
                                                className = gradeInfo.lineEmpty.includes(index) ? "empty-line line" : "line"
                                            }
                                            return (
                                                <div className={className}>
                                                    {item}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <div style={{marginBottom: "1rem"}}>
                                        <strong style={{fontSize: "16px"}}>Ваше решение</strong>
                                    </div>
                                    {
                                        gradeInfo?.userSolution.map((item, index) => {
                                            let className = "line";
                                            if (Array.isArray(gradeInfo.lineError)) {
                                                className = gradeInfo.lineError.includes(index) ? "error-line line" : "line"
                                            }
                                            return (
                                                <div className={className}>
                                                    {item}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{marginTop: "2rem"}}>
                                <div style={{fontWeight: "700"}}>Ваш результат: {gradeInfo.gradeMessage}</div>
                            </div>
                        </div>
                    ) : ""
                }

                {
                    imageRecognition ? (
                        <div className="item-recognize-dictation">
                            <hr className="item-recognize-dictation-hr"/>
                            <div style={{marginBottom: "1rem"}}>
                                <strong style={{fontSize: "16px"}}>Распознанное решение</strong>
                            </div>
                            <a href={imageRecognition} target="_blank">
                                <img
                                    src={imageRecognition}
                                    alt=""
                                    style={{maxWidth: "260px"}}
                                />
                            </a>
                        </div>

                    ) : ""
                }

            </div>


        </div>
    );
}
