import {convertVoiceToText} from './voice';
import {processTextQuestion} from './predict';
import {convertTextToSound} from "./TextToSound";
import {getRougeInfo} from "./RougeSummary";
import {getIdeaInfo} from "./IdeaDevelopment/IdeaDevelopment";
import {recognition} from "./Recognition";
import {searchInText} from "./searchInText";
import {DifferenceWord} from "./differenceWord/DifferenceWord";
import {AnalyzeTalk} from "./AnalyzeTalk";
import {parseGoogleSearch} from "./ParseGoogleSearch";
import {recognitionByteStream} from "./Recognition/v2";
import {getPathToRecognizeFile} from "./Recognition/v2/getPathToRecognizeFile";
import {recognitionMathByteStream} from "./Recognition/v2/recognitionMathByteStream";
import {getPathToRecognizeMathFile} from "./Recognition/v2/getPathToRecognizeMathFile";
import {getResultMathAnalyze, getResultMathAnalyzeFraction} from "./Recognition/v2/mathAnalyze";
import {predictCheckAnswer} from "./predict/predictCheckAnswer";
import {imageCompression} from "./ImageCompression";
import {AnalyzeSpelling} from "./Textovod/AnalyzeSpelling";
import {AnalyzePunctuation} from "./Textovod/AnalyzePunktuation";
import {AnalyzePunctuationStatus} from "./Textovod/AnalyzePunctuationStatus";
import {saveUserSpeech} from "./SaveUserSpeech";
import {HandlerRegexp} from "./Textovod/HandlerRegexp";
import {recognitionMathLatexByteStream} from "./Recognition/v2/recognitionMathLatexByteStream";

export const API = {
    DifferenceWord,
    convertVoiceToText,
    processTextQuestion,
    convertTextToSound,
    getRougeInfo,
    getIdeaInfo,
    recognition,
    searchInText,
    AnalyzeTalk,
    parseGoogleSearch,
    recognitionByteStream,
    getPathToRecognizeFile,
    recognitionMathByteStream,
    recognitionMathLatexByteStream,
    getPathToRecognizeMathFile,
    getResultMathAnalyze,
    getResultMathAnalyzeFraction,
    predictCheckAnswer,
    imageCompression,
    AnalyzeSpelling,
    AnalyzePunctuation,
    AnalyzePunctuationStatus,
    saveUserSpeech,
    HandlerRegexp
}
