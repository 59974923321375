import ReactHowler from "react-howler";
import {PauseBtn, StartBtn, StopBtn} from "../../btns";
import {Fragment, useEffect, useRef, useState} from "react";
import {parseStringForTTS} from "../../../utils/parseStringForTTS";
import {API} from "../../../api";
const textToVoice = "Компьютеры изменили технологию письма . С помощью специальных компьютерных программ можно набрать любой текст , внести в него изменения , записать текст в память компьютера , распечатать на принтере .";

const audioStatePlay = "play"
const audioStatePause = "pause"
const audioStateStop = "stop"

export const Audio = () => {

    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTrackIndex, setCurrentTrackIndex] = useState(-1)
    const [currentTrack, setCurrentTrack] = useState("")
    const [audioSrcList, setAudioSrcList] = useState([])
    const [converterIsRun, setConverterIsRun] = useState(false)
    const [audioState, setAudioState] = useState("")

    const refAudioPlayer = useRef(null)

    useEffect(() => {
        if (currentTrackIndex < 0) {
            setIsPlaying(false)
        } else {
            if (audioSrcList.length > currentTrackIndex && currentTrackIndex >= 0) {
                setCurrentTrack(audioSrcList[currentTrackIndex])
            } else {
                setIsPlaying(false)
            }
        }
    }, [currentTrackIndex])

    useEffect(() => {
        if (converterIsRun) {
            convertTextToSound().then()
        }
    }, [converterIsRun])

    useEffect(() => {
        if (currentTrack) {
            setIsPlaying(true)
        }
    }, [currentTrack])

    useEffect(() => {
        setConverterIsRun(true)
    }, [])

    useEffect(() => {
        if (audioState) {
            console.log('audioState', audioState)
            switch (audioState) {
                case audioStatePause:
                    if (refAudioPlayer.current) {
                        refAudioPlayer.current.pause()
                    }
                    break;
                case audioStateStop:
                    setCurrentTrackIndex(-1)
                    setIsPlaying(false)
                    if (refAudioPlayer.current) {
                        refAudioPlayer.current.stop()
                    }
                    break;
                default:
                    if (currentTrackIndex < 0) {
                        setCurrentTrackIndex(currentTrackIndex + 1)
                    }
                    if (refAudioPlayer.current) {
                        refAudioPlayer.current.play()
                    }
                    setIsPlaying(true)
                    break;
            }
        }
    }, [audioState])


    const convertTextToSound = async () => {
        const chunks = await parseStringForTTS(textToVoice);
        setAudioSrcList([])
        for (let item of chunks) {
            const audio = await API.convertTextToSound(item);
            const url = URL.createObjectURL(new Blob([audio.data], {type: 'audio/mpeg'}))
            setAudioSrcList(audioSrc => [...audioSrc, url])
        }
    }

    const changeStatePlay = (state) => {
        setAudioState(state)
    }

    const onEndPlayingAudio = () => {
        setCurrentTrackIndex(currentTrackIndex + 1)
    }

    return (
        <Fragment>
            {
                currentTrack &&
                <div>
                    <ReactHowler
                        src={currentTrack}
                        html5={true}
                        format={['audio/mpeg']}
                        playing={isPlaying}
                        onEnd={onEndPlayingAudio}
                        ref={refAudioPlayer}
                    />
                </div>
            }
            <div style={{fontSize: "20px", marginBottom: "2rem"}}>
                <strong>
                    Прослушайте диктант
                </strong>
            </div>
            <div className="recognize-dictation-item">
                <StartBtn
                    onClick={() => changeStatePlay(audioStatePlay)}
                    isActive={audioState !== audioStatePlay}
                />
                <PauseBtn
                    onClick={() => changeStatePlay(audioStatePause)}
                    isActive={audioState === audioStatePlay}
                    label={''}
                />
                <StopBtn
                    onClick={() => changeStatePlay(audioStateStop)}
                    isActive={audioState === audioStatePlay || audioState === audioStatePause}
                    label={''}
                />
            </div>
        </Fragment>
    )
}
