import {HomeScreen} from "../../components/HomeScreen";
import {useTranslation} from "react-i18next";
import {RecognizeItemMaths} from "../../components/Recognize/RecognizeItemMaths";
import Latex from 'react-latex'
import {MathJaxContext, MathJax} from "better-react-mathjax";
import {RecognizeItemMathsLatex} from "../../components/Recognize/RecognizeItemMathsLatex";

export function MathsLatex() {

    const tr = "\\frac {30x}{5}=10-7(1(+11)"

    const {t} = useTranslation();

    return (
        <main className="main_content page-dictation">
            <HomeScreen description="PageQuestionBookTitle" title="PageQuestionBookTitle"
                        subtitle="PageQuestionBookTitle"/>
            <section className="video-section" id="videoContainer">
                <div className="container_center">
                    <RecognizeItemMathsLatex/>
                </div>
            </section>
        </main>
    );
}
