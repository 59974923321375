export const ParseTextOffset = (text, offset, length, color = "#FF0000AD", symbol = "") => {

    let regex = /<(?:.|\n)*?>/gm;
    let newStr = text.replace(regex, "")

    let newNewStr = []

    let step = 0

    for (let item of newStr.split("")) {
        if (offset === step) {
            newNewStr.push(`<span style="background: ${color}">${symbol === "" ? item : symbol}`)
        } else if (length + offset === step) {
            newNewStr.push(`</span>${item}`)
        } else {
            newNewStr.push(item)
        }

        step++
    }

    return newNewStr.join("")

}
