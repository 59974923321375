import {HomeScreen} from "../../components/HomeScreen";
import {useTranslation} from "react-i18next";
import Parser from 'html-react-parser';
import {Link} from "react-router-dom";

export function Recognition() {

    const {t, i18n} = useTranslation();

    const availableLessons = [
        {
            id: 1,
            name: "Диктант",
            link: `/recognize/dictation/${i18n.language}`,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">\n' +
                '  <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />\n' +
                '</svg>\n'
        },
        {
            id: 2,
            name: "Простое уравнение",
            link: `/recognize/maths/${i18n.language}`,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">\n' +
                '  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />\n' +
                '</svg>\n'
        },
        {
            id: 3,
            name: "Уравнение с дробями",
            link: `/recognize/maths/latex/${i18n.language}`,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">\n' +
                '  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />\n' +
                '</svg>\n'
        }
    ]


    return (
        <main className="main_content">
            <HomeScreen description="PageQuestionBookTitle" title="PageQuestionBookTitle"
                        subtitle="PageQuestionBookTitle"/>
            <section className="video-section" id="videoContainer">
                <div className="container_center">
                    <h1 className="homeScreen__title" style={{display: 'flex', justifyContent: 'center'}}>
                        {t('AvailableLessons')}
                    </h1>
                    <div style={{display: "flex", justifyContent: 'center'}}>
                        {
                            availableLessons.map((item, index) => {
                                return (
                                    <Link
                                        key={index}
                                        to={item.link}
                                        style={{
                                            color: "black",
                                            textDecorationLine: "none",
                                            margin: "1rem",
                                            display: 'flex',
                                        }}
                                    >
                                        <div style={
                                            {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                padding: "1rem",
                                                cursor: "pointer",
                                                alignItems: 'center',
                                            }
                                        }>
                                            <div style={{minWidth: "80px", width: "60px", paddingBottom: "1rem"}}>
                                                {Parser(item.icon)}
                                            </div>
                                            {item.name}
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </main>
    );
}
