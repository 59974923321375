import * as axios from 'axios';
//TODO change point
const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/api-dialog-112/predict';
const createBody = (req) => {
    return {
        text: req.text,
        user_id: req.user_id
    }
};
const createCfg = () => {
    return {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    }
}

export async function AnalyzeTalk(req) {
    try {
        return await axios.post(createRoute(), createBody(req), createCfg());
    } catch (err) {
        return {
            data: {
                type: "error"
            }
        };
    }
}
