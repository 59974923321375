import {HomeScreen} from "../../components/HomeScreen";
import {TalkVideo} from "../../components/TalkVideo";
import {TalkV2} from "../../components/Talk/v2";

export function TalkPage() {
    return (
        <main className="main_content">
            <HomeScreen
                title={'PageTalkTitle'}
                subtitle={'PageTalkSubtitle'}
                description={'PageTalkDescription'}
            />
            <div className="container_center">
                <TalkVideo/>
                <TalkV2/>
            </div>
        </main>
    );
}
