import * as React from "react";

export const CardStateV2 = ({cardData = undefined, userMessage = undefined, currentState = undefined}) => {

    const getRenderStringInfo = (name = "", state = "", error = false) => {
        const color = error ? "red" : "black"
        return (

            <div
                style={{fontSize: "16px", marginTop: '5px', color: color}}>
                <strong>{name}: </strong>
                {state}
            </div>
        )
    }

    const getUserName = () => {
        let name = "";
        if (cardData?.name?.last) {
            name += `${cardData.name.last} `
        }

        if (cardData?.name?.first) {
            name += `${cardData.name.first} `
        }

        if (cardData?.name?.middle) {
            name += `${cardData.name.middle} `
        }
        return name
    }

    const getAddress = () => {
        let address = ""

        if (!cardData?.address) {
            return ""
        }

        if (cardData?.address['улица'] && cardData?.address['улица'] !== null) {
            address += `улица ${cardData.address['улица']} `
        }

        if (cardData?.address['дом'] && cardData?.address['дом'] !== null) {
            address += `дом ${cardData.address['дом']} `
        }

        if (cardData?.address['квартира'] && cardData?.address['квартира'] !== null) {
            address += `квартира ${cardData.address['квартира']} `
        }

        if (address === "" && cardData?.address?.raw) {
            address += cardData?.address?.raw
        }

        return address;
    }

    return (
        <div style={{flex: 2}}>
        {/*    <div style={{marginBottom: "2rem"}}>
                <div style={{
                    borderBottom: "2px solid",
                    fontWeight: "bold",
                    fontSize: "18px",
                    paddingBottom: "5px"
                }}>
                    Текущее состояние
                </div>
                <div style={{marginTop: '5px', fontSize: "16px"}}>
                    {currentState}
                </div>
            </div>*/}
            <div style={{marginBottom: "2rem"}}>
                <div style={{
                    borderBottom: "2px solid",
                    fontWeight: "bold",
                    fontSize: "18px",
                    paddingBottom: "5px"
                }}>
                    Карточка вызова
                </div>
                <div style={{marginTop: '5px'}}>
                    {
                        cardData?.action ?
                            getRenderStringInfo("Тип обращения", cardData?.action === "ambulance" ? "Скорая медицинская помощь" : "Пожарная охрана")
                            : ""
                    }
                    {
                        getUserName() !== "" ? getRenderStringInfo("ФИО", getUserName()) : ""
                    }
                    {
                        getAddress() !== "" ? getRenderStringInfo("Адрес", getAddress()) : ""
                    }
                    {
                        cardData?.fire_area ? getRenderStringInfo("Площадь возгорания", cardData.fire_area) : ""
                    }
                    {
                        cardData?.victim_status ? getRenderStringInfo("Текущее состояние", cardData?.victim_status) : ""
                    }
                    {
                        cardData?.victim_age ? getRenderStringInfo("Возраст", cardData?.victim_age) : ""
                    }
                    {
                        cardData?.description ? getRenderStringInfo("Описание", cardData?.description) : ""
                    }
                    {
                        cardData?.error ? getRenderStringInfo("Ошибка", cardData?.error, true) : ""
                    }
                </div>
            </div>
            <div style={{marginBottom: "2rem"}}>
                <div style={{
                    borderBottom: "2px solid",
                    fontWeight: "bold",
                    fontSize: "18px",
                    paddingBottom: "5px"
                }}>
                    Дополнительная информация
                </div>
                <div style={{marginTop: '5px'}}>
                    {
                        userMessage.map((item, index) => {
                            return <div style={{fontSize: "16px"}} key={index}>{item}</div>
                        })
                    }
                </div>
            </div>
        </div>
    )

}
