import * as axios from 'axios';

const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/textovod-regexp/handler/text';
const createBody = (data) => {
    return {
        text: data
    }
};
const createCfg = () => {
    return {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    }
}

export async function HandlerRegexp(data) {
    try {
        return await axios.post(createRoute(), createBody(data), createCfg());
    } catch (err) {
        return null
    }
}
