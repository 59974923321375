import {useTranslation} from "react-i18next";
import * as React from "react";
import {useState} from "react";
import {recognizeImageDictation} from "../../utils/recognizeImage";
import {Audio} from "./Dictation/Audio";
import {Example} from "./Dictation/Example";
import {Image} from "./Dictation/Image";
import {Result} from "./Dictation/Result";

export function RecognizeItemDictation() {

    const {t} = useTranslation();
    const [isRunRecognition, setIsRunRecognition] = useState(false);
    const [resultRecognition, setResultRecognition] = useState("");
    const [imageId, setImageId] = useState(undefined)

    const runRecognition = async (file) => {

        const recognizeImageResult = await recognizeImageDictation(file)

        if (recognizeImageResult.error) {
            console.error("Error recognizeImage")
        } else {
            setImageId(recognizeImageResult.imageID)
            setResultRecognition(recognizeImageResult.message)
        }

        console.log("runRecognition data", recognizeImageResult)
    }

    const handleChangeExampleFile = (file) => {
        setIsRunRecognition(true);
        runRecognition(file).then()
    }


    return (
        <div className="container-recognize-dictation">
            <Audio/>
            <div className="container-recognize-dictation-result">
                <Example handler={handleChangeExampleFile} isRunRecognition={isRunRecognition}/>
                <Result _resultRecognition={resultRecognition} isRunRecognition={isRunRecognition}/>
                <Image imageId={imageId} isRunRecognition={isRunRecognition}/>
            </div>
        </div>
    );
}
