import ReactHowler from "react-howler";
import * as React from "react";
import {useRef} from "react";

export const RatsiyaBackground = ({isRun = false}) => {

    const srcRef = useRef(null);

    const ratsiyaTrackSrc = "/sounds/ratsiya.mp3";

    const onEndPlaying = () => {
        srcRef.play();
    }

    return (
        <div style={{display: 'none'}}>
            {
                isRun &&
                <ReactHowler
                    ref={srcRef}
                    src={ratsiyaTrackSrc}
                    onEnd={onEndPlaying}
                    format={['mp3']}
                    volume={0.03}
                />
            }
        </div>
    );
}
