export const LineError = ({value = {}, fullText = "", handleRShowErrorHtml, type = "spelling"}) => {

    const getErrorWord = (offset, length) => {
        return fullText.substring(offset, length + offset)
    }

    const getRightVariables = (rep) => {

        const right = []

        for (let item of rep) {
            if (right.length === 2) {
                break
            }

            right.push(item)
        }

        return right.join(", ")
    }

    const clickShowError = (value) => {
        if (handleRShowErrorHtml) {
            handleRShowErrorHtml(value)
        } else {
            console.error("Ошибка при показе ошибки!!! не передана функция обратного вызова для", value)
        }
    }

    return (
        <div>
            <div style={{
                display: "flex",
                margin: "auto",
                width: "100%",
                marginBottom: "10px"
            }}>
                <div style={{width: "33.33333%"}}>
                    {getErrorWord(value.offset, value.length)}
                </div>
                <div style={{width: "33.33333%"}}>
                    {type === "spelling" ? getRightVariables(value.rep) : value.rep}
                </div>
                <div style={{width: "33.33333%"}}>
                    <div onClick={() => {
                        clickShowError(value)
                    }} style={{cursor: "pointer"}}>
                        Показать
                    </div>
                </div>
            </div>
            <hr/>
        </div>
    )

}
