import * as axios from 'axios';

const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/image-compression/';
const createBody = (req) => {
    const formData = new FormData
    formData.set("data", req)
    return formData
};
const createCfg = () => {
    return {
        responseType: 'blob',
        headers: {
            "Content-Type": "image/jpeg",
        }
    }
}

export async function imageCompression(req){
    try{
        const res = await axios.post(createRoute(), createBody(req), createCfg());
        return res;
    }
    catch(err){
        return null;
    }
}
