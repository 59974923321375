import {HomeScreen} from "../../components/HomeScreen";
import {useTranslation} from "react-i18next";
import {RecognizeItemDictation} from "../../components/Recognize/RecognizeItemDictation";
import {Recognize} from "../../components/Recognize";

export function Dictation() {

    const {t} = useTranslation();

    return (
        <main className="main_content page-dictation">
            <HomeScreen description="PageQuestionBookTitle" title="PageQuestionBookTitle"
                        subtitle="PageQuestionBookTitle"/>
            <section className="video-section" id="videoContainer">
                <div className="container_center">
                    <RecognizeItemDictation/>
                </div>
            </section>
        </main>
    );
}
