import {Fragment, useEffect, useState} from "react";
import Parser from "html-react-parser";
import {Loader} from "../../../Loader";
import MD5 from "crypto-js/md5";
import {ParseTextOffset} from "../../../../utils/parseTextOffset";
import {LineError} from "./LineError";


export const Analyze = ({data = {}, type = "spelling"}) => {

    const [rawAnalyzeData, setRawAnalyzeData] = useState([])
    const [analyzeData, setAnalyzeData] = useState([])
    const [referenceString, setReferenceString] = useState("")
    const [htmlString, setHtmlString] = useState("")
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (data) {
            setRawAnalyzeData(data.data)
            setReferenceString(data.text)
            setHtmlString(data.text)
            setIsError(data.error)
        }
    }, [data])

    useEffect(() => {
        if (rawAnalyzeData) {
            const _tmp = {}
            for (let item of rawAnalyzeData) {
                if (!_tmp.hasOwnProperty(MD5(item.mes).toString())) {
                    _tmp[MD5(item.mes)] = {
                        title: getTitleMessage(item.mes),
                        items: [item]
                    }
                } else {
                    _tmp[MD5(item.mes)].items.push(item)
                }
            }
            for (let key in _tmp) {
                setAnalyzeData(prevState => [...prevState, _tmp[key]])
            }
        }
    }, [rawAnalyzeData])

    const clickShowError = (value) => {
        setHtmlString(ParseTextOffset(referenceString, value.offset, value.length, value.color, value.symbol))
    }

    const getTitleMessage = (message) => {
        switch (message) {
            case "register":
                return "Возможно не верный регистр"
            case "add":
                return "Возможно нужно добавить"
            case "delete":
                return "Возможно нужно удалить"
            default:
                return message
        }
    }


    return (
        <div>
            {
                isError &&
                <div>ERROR</div>
            }
            {
                !isError && referenceString &&
                <Fragment>
                    <div style={{marginBottom: "1rem", marginTop: "1rem"}}>
                        {Parser(htmlString)}
                    </div>
                   {
                        analyzeData.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div style={{marginBottom: "1rem", marginTop: "1rem"}}>
                                        <strong style={{fontSize: "14px"}}>{item.title}</strong>
                                    </div>
                                    {
                                        item.items.length > 0 &&
                                        <div style={{
                                            display: "flex",
                                            margin: "auto",
                                            width: "100%",
                                            marginBottom: "1rem"
                                        }}>
                                            <div style={{width: "33.33333%"}}>
                                                <strong>Слово с ошибкой</strong>
                                            </div>
                                            <div style={{width: "33.33333%"}}>
                                                <strong>
                                                    Возможные варианты
                                                </strong>
                                            </div>
                                            <div style={{width: "33.33333%"}}>
                                                <strong>
                                                    Действия
                                                </strong>
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.items.map((value, index2) => {
                                            return (
                                                <LineError
                                                    key={index2}
                                                    value={value}
                                                    fullText={referenceString}
                                                    handleRShowErrorHtml={clickShowError}
                                                    type={type}
                                                />
                                            )
                                        })
                                    }

                                </div>
                            )
                        })
                    }
                </Fragment>
            }
        </div>
    )
}
