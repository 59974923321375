import {useTranslation} from "react-i18next";
import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {API} from "../../api";
import {Loader} from "../Loader";
import {getResultMathAnalyze, getResultMathAnalyzeFraction} from "../../api/Recognition/v2/mathAnalyze";
import {recognizeImageMathLatex} from "../../utils/recognizeImage";
import {MathJaxContext, MathJax} from "better-react-mathjax";
import MD5 from "crypto-js/md5";

export function RecognizeItemMathsLatex() {

    const [resultLatex, setResultLatex] = useState(null)

    const [isRunRecognition, setIsRunRecognition] = useState(false);
    const [imageRecognition, setImageRecognition] = useState('');
    const [gradeInfo, setGradeInfo] = useState({})

    useEffect(() => {
        if (resultLatex) {
            getPathToRecognizeFile(resultLatex.imageID)
            checkResult(resultLatex.solution.join("##"))
        }
    }, [resultLatex])

    const handleChange = (event) => {
        runRecognition(event.target.files[0])
    }

    const runRecognition = async (file) => {

        setIsRunRecognition(true);

       const result = await recognizeImageMathLatex(file)
        if (result.error) {
            console.error("My error")
        } else {
            setResultLatex(result)
        }
        setIsRunRecognition(false);
    }

    const getPathToRecognizeFile = (id) => {
        API.getPathToRecognizeMathFile(id)
            .then(({data}) => {
                setImageRecognition(URL.createObjectURL(new Blob([data], {type: "image/jpeg"})))
            })
            .catch(error => {
                console.error("RecognitionError", error)
            })
    }

    const checkResult = (result) => {
        console.log("checkResult", result)
        API.getResultMathAnalyzeFraction(result)
            .then(({data}) => {
                setGradeInfo({})
                const referenceSolution = [];
                const userSolution = [];
                console.log("result data", data.referenceSolution)
                for (const key in data.referenceSolution) {
                    referenceSolution.push(data.referenceSolution[key])
                }
                for (const key in data.userSolution) {
                    userSolution.push(data.userSolution[key])
                }

                data.referenceSolution = referenceSolution
                data.userSolution = userSolution

                console.log("data", data)
                setGradeInfo(data)
                // setImageRecognition(URL.createObjectURL(new Blob([data], {type: "image/jpeg"})))
            })
            .catch(error => {
                console.error("getResultMathAnalyze", error)
            })
    }


    return (
        <div className="container-recognize-dictation">
            <div style={{fontSize: "20px", display: 'flex', flexDirection: 'column'}}>
                <strong>
                    Решите уравнение по шагам
                </strong>
                <MathJaxContext>
                    <MathJax style={{
                        fontSize: "26px",
                        backgroundColor: "#d7d7d7",
                        padding: "1rem",
                        width: "fit-content",
                        marginTop: "1rem"
                    }}>
                    {`\\(\\frac {36}{6}-x=\\frac {40}{2}\\)`}
                    </MathJax>

                </MathJaxContext>
            </div>

            <div className="recognize-dictation-item">

            </div>
            <div className="container-recognize-dictation-result">
                <div className="item-recognize-dictation">
                    <div>
                        <div style={{marginBottom: "1rem"}}>
                            <strong style={{fontSize: "16px"}}>Пример фотографии с решением</strong>
                        </div>
                        <img style={{maxWidth: "250px"}} src="/img/recognizer-maths-fac-example.jpg" alt=""/>
                    </div>
                    <div style={{display: "grid", gridAutoFlow: "column"}}>
                        <input type="file" style={{marginTop: "1rem"}} onChange={handleChange}/>
                        <div className="recognize-result-loader">
                            <Loader hidden={!isRunRecognition}/>
                        </div>
                    </div>


                </div>

                {
                    gradeInfo?.grade ? (
                        <div className="item-recognize-dictation item-recognize-dictation-math">
                            <hr className="item-recognize-dictation-hr"/>
                            <div style={{marginBottom: "1rem", display: "flex", justifyContent: "center"}}>
                                <strong style={{fontSize: "16px"}}>Оценка решения</strong>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <div>
                                    <div style={{marginBottom: "1rem"}}>
                                        <strong style={{fontSize: "16px"}}>Правильное решение</strong>
                                    </div>
                                    <MathJaxContext>
                                        {
                                            gradeInfo?.referenceSolution.map((item, index) => {
                                                let className = "line";
                                                if (Array.isArray(gradeInfo.lineEmpty)) {
                                                    className = gradeInfo.lineEmpty.includes(index) ? "empty-line line" : "line"
                                                }
                                                return (
                                                    <div className={className} key={MD5(item)}>
                                                        <MathJax>{`\\(${item}\\)`}</MathJax>
                                                    </div>
                                                )
                                            })
                                        }
                                    </MathJaxContext>
                                </div>
                                <div>
                                    <div style={{marginBottom: "1rem"}}>
                                        <strong style={{fontSize: "16px"}}>Ваше решение</strong>
                                    </div>
                                    <MathJaxContext>
                                        {
                                            gradeInfo?.userSolution.map((item, index) => {
                                                let className = "line";
                                                if (Array.isArray(gradeInfo.lineError)) {
                                                    className = gradeInfo.lineError.includes(index) ? "error-line line" : "line"
                                                }
                                                return (
                                                    <div className={className} key={MD5(item)}>
                                                        <MathJax>{`\\(${item}\\)`}</MathJax>
                                                    </div>
                                                )
                                            })
                                        }
                                    </MathJaxContext>
                                </div>
                            </div>
                            <div style={{marginTop: "2rem"}}>
                                <div style={{fontWeight: "700"}}>Ваш результат: {gradeInfo.gradeMessage}</div>
                            </div>
                        </div>
                    ) : ""
                }

                {
                    imageRecognition ? (
                        <div className="item-recognize-dictation">
                            <hr className="item-recognize-dictation-hr"/>
                            <div style={{marginBottom: "1rem"}}>
                                <strong style={{fontSize: "16px"}}>Распознанное решение</strong>
                            </div>
                            <a href={imageRecognition} target="_blank">
                                <img
                                    src={imageRecognition}
                                    alt=""
                                    style={{maxWidth: "260px"}}
                                />
                            </a>
                        </div>

                    ) : ""
                }

            </div>


        </div>
    );
}
