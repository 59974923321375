
export const TalkVideo = () => {
    return (
        <div id="videoContainer" style={{marginTop: "3rem"}}>
            <iframe width="100%" height="692" src="https://www.youtube.com/embed/GKG18e-2avg"
                    title="DeepTalk. Цифровой оператор системы-112" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </div>
    )
}
