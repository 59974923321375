import {StartBtn, StopBtn} from "../../btns";
import style from '../style.module.css'
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {RatsiyaBackground} from "../../RatsiyaBackground";
import {CardStateV2} from "./cardState";
import RecordRTC from "recordrtc"
import ReactHowler from "react-howler";

export const TalkV2 = () => {

    const [changeUppercase, setChangeUppercase] = useState(false)
    const [chatItem, setChatItem] = useState([])
    const [runRatsiya, setRunRatsiya] = useState(false)

    const [recorder, setRecorder] = useState(null)
    const [trackList, setTrackList] = useState([])
    const [currentTrack, setCurrentTrack] = useState("")
    const [isPlaying, setIsPlaying] = useState(false)
    const [cardData, setCardData] = useState({})
    const [userMessage, setUserMessage] = useState([])
    const [currentStateIsRun, setCurrentStateIsRun] = useState(false)
    const [isRunPreview, setIsRunPreview] = useState(false)
    const [isEndTrack, setIsEndTrack] = useState(false)

    const WSClient = useRef(null)
    const capitalizeFirst = useRef(false)
    const userIsVoice = useRef(false)

    useEffect(() => {
        if (recorder) {
            recorder.startRecording()
            recorder.pauseRecording()
        }
    }, [recorder])

    useEffect(() => {
        if (WSClient.current) {
            if (!WSClient.current.onopen) {
                WSClient.current.onopen = () => {
                    console.log("Соединение с сервером по web socket установленно")
                    sendMessage([], 1)
                }
            }

            if (!WSClient.current.onclose) {
                WSClient.current.onclose = () => {
                    console.log("Соединение с сервером по web socket закрыто")
                }
            }

            if (!WSClient.current.onmessage) {
                WSClient.current.onmessage = onMessageWS
            }
        }
    }, [WSClient.current])

    useEffect(() => {
        if (trackList.length && currentTrack === "") {
            console.log(`Установлен новый аудио файл`)
            setCurrentTrack(`/sounds/112/${trackList.shift()}.mp3`)
        }
    }, [trackList])

    useEffect(() => {
        if (currentTrack) {
            console.log(`Включено воспроизведение дорожки - ${currentTrack}`)
            setIsPlaying(true)
        }
    }, [currentTrack])

    useEffect(() => {
        if (recorder) {
            if (isPlaying) {
                console.log("Система запустила воспроизведение голоса и поставила микрофон на паузу")
                if (WSClient.current && WSClient.current.readyState) {
                    sendMessage([], 2)
                }
                recorder.pauseRecording()
            } else {
                console.log("Система завершила воспроизведение голоса и возобновила прослушивание микрофон")
                if (WSClient.current && WSClient.current.readyState) {
                    sendMessage([], 1)
                }
                recorder.resumeRecording()
            }
        }
    }, [isPlaying])

    const onMessageWS = (data) => {
        console.log("Получено новое сообщение по web socket")
        data = JSON.parse(data.data)
        if (data.messageType !== "predict") {
            console.log("Анализ сообщения сказанного пользователем")
            userIsVoice.current = true
            if (capitalizeFirst.current) {
                console.log("Переводим первые буквы в верхний регистр")
                data.message = data.message.split(' ').map((item) => capitalizeFirstLetter(item)).join(' ')
                capitalizeFirst.current = false
            }

            setUserMessage(item => [...item, data.message])
            addMessageChat(data.message, false)
            return
        }
        console.log("Анализ сообщения от системы")

        console.log("MESSAGE WS ", data)
        switch (data.type) {
            case "question":
            case "not_enough":
                console.log("Система задает новый вопрос")
                if (Number(data.ID) === 2 || Number(data.ID) === 3) {
                    capitalizeFirst.current = true
                }
                if (Number(data.ID) === 0 && data.type === "not_enough") {
                    addMessageChat(data.data, true)
                    return;
                }
                setCurrentTrack("")
                setTrackList([])
                setTrackList(Array.isArray(data.ID) ? data.ID : [data.ID])
                addMessageChat(data.data, true)
                break;
            case "result":
                console.log("recognize result", data)
                console.log("Система сформировала результат")
                capitalizeFirst.current = false
                setCurrentTrack("")
                setTrackList([])
                setTrackList([data.ID])
                setCardData(data.result)
                addMessageChat(data.data, true, true)
                break;
            case "waiting_next":
                console.log("Система выполняет дозапрос")
                break;
            case "theme_not_defined":
                console.log("Система не слышит пользователя")
                if (userIsVoice.current) {
                    addMessageChat(data.data, true)
                }
                break;
            default:
                addMessageChat(`Ошибка определения - "${data.data}"`, true, true)
                break;
        }
    }

    useEffect(() => {
        const newListener = async () => {
            console.log("Система сформировала конфигурацию для прослушивания аудио")
            const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
            setRecorder(RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/wav',
                recorderType: RecordRTC.StereoAudioRecorder,
                desiredSampRate: 16000,
                timeSlice: 500,
                numberOfAudioChannels: 1,
                disableLogs: false,
                ondataavailable: (blob) => {
                    blob.arrayBuffer().then((data => {
                        sendMessage(data)
                    }))
                }
            }))
        }
        newListener()
    }, [])




    const newOnClickStart = () => {
        openWS()
        console.log("Система запущена")
        setChatItem([])
        setCurrentStateIsRun(true)
        setUserMessage([])
        setCardData({})
        setRunRatsiya(true)
        runPreview()
    }

    const newOnClickStop = () => {
        setCurrentStateIsRun(false)
        setRunRatsiya(false)
        setCurrentTrack("")
        setIsPlaying(false)
        setIsRunPreview(false)
        if (recorder) {
            recorder.pauseRecording()
        }
        if (WSClient.current) {
            closeWSConnect()
        }
        console.log("Система остановлена")
    }

    const closeWSConnect = () => {
        console.log("Закрываем соединение с web socket")
        WSClient.current.close()
    }

    const sendMessage = (message = [], type = 0) => {
        if (WSClient.current.readyState === WSClient.current.OPEN) {
            console.log("sendMessage", type)
            console.log("Отправляем сообщение в web socket")
            WSClient.current.send(JSON.stringify({
                data: Array.from(new Uint8Array(message)),
                typeRun: type
            }))
        }
    }

    const addMessageChat = (message, system = false, end = false) => {
        {
            if (end) {
                setIsEndTrack(true)
                console.log("Система получила последнее сообщение")

            }
            if (changeUppercase) {
                message = message.split(' ').map((item) => capitalizeFirstLetter(item)).join(' ')
                setChangeUppercase(false)
            }
            console.log("Добавлено сообщение в чат")
            setChatItem(item => [...item, {
                system: system,
                message: message
            }])
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const setNextTrack = () => {
        if (isRunPreview) {
            console.log("Система завершило воспроизведения приветственного ролика")
            setIsRunPreview(false)
            console.log("Система запустила прослушивание микрофона")
            recorder.resumeRecording()
        }
        if (trackList.length) {
            console.log("Запуск дорожки из массива")
            setCurrentTrack(`/sounds/112/${trackList.shift()}.mp3`)
            setIsPlaying(true)
        } else {
            console.log("Все дорожки воспроизведены")
            if (isEndTrack) {
                setIsEndTrack(false)
                newOnClickStop()
            }
            setIsPlaying(false)
        }
    }

    const onEndPlaying = () => {
        console.log("Закончилось воспроизведение дорожки")
        setNextTrack()
    }

    const runPreview = () => {
        setCurrentTrack("/sounds/112/1.mp3")
        setIsRunPreview(true)
        addMessageChat("Здравствуйте что у вас произошло?", true)
    }

    const openWS = () => {
        console.log("Создается новое подключение к web socket")
        WSClient.current = undefined
        WSClient.current = new WebSocket("wss://demo.deeptalk.tech/websocket/ws")
        // WSClient.current = new WebSocket("ws://localhost/converter-ws")
        // WSClient.current = new WebSocket("ws://localhost/websocket")
    }

    return (
        <div style={{marginTop: "4rem"}}>
            <RatsiyaBackground isRun={runRatsiya}/>
            <div style={{display: "flex", flexDirection: "row", gap: "5rem", marginBottom: "2rem"}}>
                <div style={{flex: 2}}>
                    <StartBtn onClick={newOnClickStart} isActive={!currentStateIsRun}></StartBtn>
                    <StopBtn onClick={newOnClickStop} isActive={currentStateIsRun}></StopBtn>
                </div>
                <div style={{display: 'none'}}>
                    {
                        currentTrack &&
                        <ReactHowler
                            src={currentTrack}
                            onEnd={onEndPlaying}
                            playing={isPlaying}
                            format={['mp3']}
                        />
                    }
                </div>
                <div className={style.containerChat}>
                    {
                        chatItem.map((item, index) => {
                            return (
                                <div key={index} className={item.system ? style.chatItemOther : style.chatItemMe}>
                                    <span className={style.chatItemText}>
                                        {item.message}
                                    </span>
                                </div>
                            );
                        })
                    }

                </div>
                <CardStateV2 cardData={cardData} userMessage={userMessage} />
            </div>
        </div>
    )
}
