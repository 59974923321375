import * as axios from 'axios';

const createRoute = (id) => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + `/recognition/get_vis/${id}`;
const createCfg = () => {
    return {
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'image/png'
        }
    }
}

export async function getPathToRecognizeFile(req) {
    try {
        return await axios.get(createRoute(req), createCfg());
    } catch (err) {
        return null;
    }
}
