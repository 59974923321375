import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {Loader} from "../../Loader";
import {API} from "../../../api";
import {Analyze} from "./Analyze";
export const Result = ({_resultRecognition, isRunRecognition}) => {

    const [stateDisplayResult, setStateDisplayResult] = useState("spelling")
    const [resultRecognition, setResultRecognition] = useState()
    const [resultRecognitionSpelling, setResultRecognitionSpelling] = useState({})
    const [resultRecognitionPunctuation, setResultRecognitionPunctuation] = useState({})
    const [punctuationTextId, setPunctuationTextId] = useState("")

    const [showLoader, setShowLoader] = useState(false)
    const [viewBlock, setViewBlock] = useState(false)

    const [showSpellingBtn, setShowSpellingBtn] = useState(false)
    const [showPunctuationBtn, setShowPunctuationBtn] = useState(false)

    useEffect(() => {
        if (isRunRecognition) {
            setShowLoader(true)
            setViewBlock(true)
        }
    }, [isRunRecognition])


    useEffect(() => {
        if (_resultRecognition) {
            setResultRecognition(_resultRecognition)
        }
    }, [_resultRecognition])

    useEffect(() => {
        if (punctuationTextId) {
            getPunctuationResultTextById()
        }
    }, [punctuationTextId])


    useEffect(() => {
        if (resultRecognition) {
            runAnalyzePunctuation()
            runAnalyzeSpelling()
        }
    }, [resultRecognition])

    useEffect(() => {
        if (resultRecognitionSpelling.text) {
            setShowSpellingBtn(true)
        }
        if (resultRecognitionPunctuation.text) {
            setShowPunctuationBtn(true)
        }

        if (resultRecognitionSpelling.text && resultRecognitionPunctuation.text) {
            setShowLoader(false)
        }
    }, [resultRecognitionSpelling, resultRecognitionPunctuation])

    const changeStateDisplayResult = (state) => {
        setStateDisplayResult(state)
    }

    const runAnalyzeSpelling = () => {
        API.AnalyzeSpelling({text: resultRecognition})
            .then(({data}) => {
                const result = {
                    text: "",
                    data: [],
                    error: false
                }

                try {
                    if (data.result) {
                        for (const item of JSON.parse(data.result)) {
                            item.color = "rgba(255,213,0,0.68)"
                            item.symbol = ""
                            result.data.push(item)
                        }
                    }

                    result.text = data.text
                } catch (e) {
                    result.error = true
                }

                setResultRecognitionSpelling(result)
            })
            .finally(() => {
            })

    }

    const runAnalyzePunctuation = () => {
        API.AnalyzePunctuation({text: resultRecognition}).then(({data}) => {

            if (data.text_id) {
                setPunctuationTextId(data.text_id)
            } else {
                setResultRecognitionPunctuation({
                    error: true
                })
            }
        })
    }

    const getPunctuationResultTextById = () => {
        API.AnalyzePunctuationStatus({text_id: punctuationTextId})
            .then(({data}) => {
                if (data.status !== 2) {
                    getPunctuationResultTextById()
                }

                const result = {
                    text: "",
                    data: [],
                    error: false
                }

                try {
                    if (data.result.punctuation && data.result.punctuation[0].paragraph) {
                        for (let item of data.result.punctuation[0].paragraph) {
                            if (item.mes === "ok") {
                                continue
                            }
                            if (item.mes === "add") {
                                item.color = "#73FF00AD"
                                item.symbol = `${item.rep} `
                                item.offset = item.offset + 1;
                            }
                            result.data.push(item)
                        }
                    }

                    result.text = data.resultText[0]
                } catch (e) {
                    result.error = true
                }
                setResultRecognitionPunctuation(result)
            })
            .finally(() => {
            })
    }

    return (

        <div style={{display: "flex", flexDirection: "column"}}>
            {
                viewBlock &&
                <Fragment>
                    <hr className="item-recognize-dictation-hr"/>

                    <div className="item-recognize-dictation">
                        <div>
                            <div style={{marginBottom: "1rem", display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <strong style={{fontSize: "16px"}}>Результат распознавания</strong>
                                </div>
                                <div>

                                    {
                                        showSpellingBtn &&
                                        <button
                                            disabled={stateDisplayResult === "spelling"}
                                            onClick={() => {changeStateDisplayResult("spelling")}}
                                        >
                                            орфография
                                        </button>
                                    }

                                    {
                                        showPunctuationBtn &&
                                        <button
                                            disabled={stateDisplayResult === "punctuation"}
                                            onClick={() => {changeStateDisplayResult("punctuation")}}
                                        >
                                            пунктуация
                                        </button>
                                    }
                                </div>
                            </div>

                            {
                                stateDisplayResult === "spelling" &&
                                <div>
                                    <Analyze
                                        data={resultRecognitionSpelling}
                                    />
                                </div>
                            }

                            {
                                stateDisplayResult === "punctuation" &&
                                <div>
                                    <Analyze
                                        data={resultRecognitionPunctuation}
                                        type={"punctuation"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <Loader hidden={!showLoader}/>
                </Fragment>
            }
        </div>
    )
}
