import {Header} from "./layouts/default/Header";
import {Main} from "./pages/main";
import {Footer} from "./layouts/default/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {English} from "./pages/english";
import {Recognition} from "./pages/recognition";
import {QuestionBook} from "./pages/questionBook";
import {TalkPage} from "./pages/talk";
import {Dictation} from "./pages/recognition/Dictation";
import {Maths} from "./pages/recognition/Maths";
import {TalkV2Page} from "./pages/talk/indexV2";
import uuid from 'react-uuid';
import {MathsLatex} from "./pages/recognition/MathsLatex";

export const userSpeechId = uuid()

export default function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<Main/>}>
                    <Route path=":lang" element={<Main/>}/>
                </Route>
                <Route path="/english/" element={<English/>}>
                    <Route path=":lang" element={<English/>}/>
                </Route>
                <Route path="/recognize/" element={<Recognition/>}>
                    <Route path=":lang" element={<Recognition/>}/>
                </Route>
                <Route path="/recognize/dictation/" element={<Dictation/>}>
                    <Route path=":lang" element={<Dictation/>}/>
                </Route>
                <Route path="/recognize/maths/" element={<Maths/>}>
                    <Route path=":lang" element={<Maths/>}/>
                </Route>
                <Route path="/recognize/maths/latex/" element={<MathsLatex/>}>
                    <Route path=":lang" element={<MathsLatex/>}/>
                </Route>
                <Route path="/questions/" element={<QuestionBook/>}>
                    <Route path=":lang" element={<QuestionBook/>}/>
                </Route>
                <Route path="/talk/" element={<TalkPage/>}>
                    <Route path=":lang" element={<TalkPage/>}/>
                </Route>
                <Route path="/talk/v2/" element={<TalkV2Page/>}>
                    <Route path=":lang" element={<TalkV2Page/>}/>
                </Route>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}
